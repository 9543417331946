import React, { FC } from 'react';

import tree from '../assets/tree-art.webp';

export const Tree: FC<{
  onTreeClick?: () => void;
  isNight?: boolean;
  noText?: boolean;
}> = ({ isNight, onTreeClick, noText = false }) => (
  <div className={'item-container'}>
    <div className={'image-container'}>
      <img
        src={tree}
        alt={'tree'}
        className={'tree'}
        data-isnight={isNight}
        onClick={() => onTreeClick?.()}
      />

      {!noText && (
        <div className="title-container">
          <h1 className="title" data-isnight={isNight}>
            {isNight ? 'Save the evening' : 'Save the date'}
          </h1>
          <h2 className="subtitle">15 mei 2025</h2>
          <h2 className={'title-names'}>Gina & Quinten</h2>
        </div>
      )}
    </div>
  </div>
);
