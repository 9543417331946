import { FC, useState } from 'react';
import { Rsvp } from '../database/invites';

export type FormData = {
  email: string;
  address: string;
  rsvp: Rsvp;
  partnerRsvp?: Rsvp;
  remark: string;
};

export const Form: FC<{
  person: string;
  partner?: string | null;
  onSubmit: (values: FormData) => void;
  pending: boolean;
}> = ({ person, partner, onSubmit, pending }) => {
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [remark, setRemark] = useState<string>('');

  const [rsvp, setRsvp] = useState<Rsvp | null>(null);
  const [partnerRsvp, setPartnerRsvp] = useState<Rsvp | null>(null);
  const [rsvpInvalid, setRsvpInvalid] = useState<boolean>(false);
  const [rsvpPartnerInvalid, setRsvpPartnerInvalid] = useState<boolean>(false);

  return (
    <div className={'item-container'}>
      <form
        className={'card card-content'}
        onSubmit={event => {
          event.preventDefault();

          let invalid = false;
          if (rsvp == null) {
            invalid = true;
            setRsvpInvalid(true);
          }
          if (!!partner && partnerRsvp == null) {
            invalid = true;
            setRsvpPartnerInvalid(true);
          }

          if (invalid) return;

          onSubmit({
            email,
            address,
            rsvp: rsvp!,
            ...(!!partner ? { partnerRsvp: partnerRsvp! } : {}),
            remark,
          });
        }}
      >
        <p className={'form-description'}>
          Om {partner ? 'jullie' : 'je'} op de hoogte te houden van eventuele
          wijzigingen, en om de foto's van die dag te delen, willen wij{' '}
          {partner ? 'jullie' : 'jou'} vragen om de volgende gegevens achter te
          laten.
        </p>

        <div className={'input-field'}>
          <input
            type={'email'}
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
            data-lpignore={'true'}
            autoComplete={'off'}
            disabled={pending}
          />
          <label>Email*</label>
        </div>

        <div className={'input-field'}>
          <input
            type={'text'}
            value={address}
            maxLength={120}
            onChange={event => setAddress(event.target.value)}
            required
            disabled={pending}
          />
          <label>Adres*</label>
        </div>

        <h4>RSVP {!!partner ? person : ''}</h4>
        <RSVPButton name={'person'} onChange={setRsvp} invalid={rsvpInvalid} />

        {!!partner && (
          <>
            <h4>RSVP {partner}</h4>
            <RSVPButton
              name={'partner'}
              onChange={setPartnerRsvp}
              invalid={rsvpPartnerInvalid}
            />
          </>
        )}

        <div className={'input-field'}>
          <textarea
            value={remark}
            maxLength={500}
            onChange={event => setRemark(event.target.value)}
            disabled={pending}
          />
          <label>Opmerkingen</label>
        </div>

        <button className={'button-custom'} type={'submit'} disabled={pending}>
          {pending ? <span className="spinner"></span> : 'Bevestig'}
        </button>
      </form>
    </div>
  );
};

const RSVPButton: FC<{
  name: string;
  onChange: (rsvp: Rsvp) => void;
  invalid: boolean;
}> = ({ name, onChange, invalid }) => {
  return (
    <div>
      <div className="radio-group">
        <label className="radio">
          <input
            type="radio"
            name={name}
            value={Rsvp.Yes}
            onChange={event => onChange(event.target.value as Rsvp)}
          />
          <span className="radio-custom">Ja</span>
        </label>
        <label className="radio">
          <input
            type="radio"
            name={name}
            value={Rsvp.Maybe}
            onChange={event => onChange(event.target.value as Rsvp)}
          />
          <span className="radio-custom">Misschien</span>
        </label>
        <label className="radio">
          <input
            type="radio"
            name={name}
            value={Rsvp.No}
            onChange={event => onChange(event.target.value as Rsvp)}
          />
          <span className="radio-custom">Nee</span>
        </label>
      </div>

      {invalid && (
        <span className={'radio-validation'}>
          Laat alstjeblieft een RSVP achter
        </span>
      )}
    </div>
  );
};
